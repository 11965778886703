import { Injectable } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import {
  BehaviorSubject,
  catchError,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public user$: Observable<any> = this.userSubject.asObservable();
  constructor(
    private oauthService: OAuthService,
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthEvent) {
        if (event.type === 'token_received') {
          this.userLoginSuccess();
          this.fetchUserDetails(false).subscribe();
        }
      }
    });
  }

  public login(targetUrl?: string) {
    sessionStorage.setItem('login_method', 'oidc');
    this.oauthService.initLoginFlow();
  }

  public logout() {
    // sessionStorage.clear();
    this.oauthService.logOut();
  }
  public refresh() {
    this.oauthService.silentRefresh();
  }
  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }
  public runInitialLoginSequence(): Promise<boolean> {
    if(sessionStorage.getItem('login_method') === 'custom' && !sessionStorage.getItem('access_token')) {
      this.router.navigate(['/sign-in']); 
    }
    this.oauthService.configure(environment.authCodeFlowConfig);
    return this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (this.hasValidToken()) {
        this.fetchUserDetails(sessionStorage.getItem('login_method') === 'custom').subscribe();
        return true;
      }
      return false;
    });
  }

  userLoginSuccess() {
    console.log('User login success');
  }

  public get accessToken() {
    return this.oauthService.getAccessToken();
  }
  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }
  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }
  public get idToken() {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl() {
    if (sessionStorage.getItem('login_method') === 'custom') {
      sessionStorage.clear();
      this.router.navigate(['/sign-in']);
    }
    return this.oauthService.logoutUrl;
  }
  customLogout() {
    sessionStorage.clear();
    this.router.navigate(['/sign-in']);
  }
  fetchUserDetails(clientUser: boolean): Observable<any> {
    return this.http.get<any>(environment.restHost + '/user/me').pipe(
      tap((user) => {
        user.client_user = clientUser;
        this.userSubject.next(user);
      })
    );
  }
  fetchUserDetailsMock(): Observable<any> {
    const mockUser = { can_update: true, name: 'Admin', email: '' };
    return of(mockUser).pipe(tap((user) => this.userSubject.next(user)));
  }
  getUserDetails(): any {
    return this.userSubject.value;
  }
  isAdmin(): boolean {
    const user = this.userSubject.value;
    return user && user.system_admin;
  }
  canUpdate(): boolean {
    const user = this.userSubject.value;
    return user && user.can_update;
  }
  isClientUser() {
    const user = this.userSubject.value;
    return user && user.client_user;
  }

  public loginWithUsernameAndPassword(email: string, password: string) {
    const body = { email, password };

    return this.http.post<{ token: string }>(
      `${environment.restHost}/auth/login`,
      body
    ).pipe(
      tap((response) => {
        sessionStorage.setItem('access_token', response.token);
        sessionStorage.setItem('login_method', 'custom');
      }),
      switchMap(() => this.fetchUserDetails(true)),
      tap(() => {
        this.spinner.hide();
        this.router.navigate(['/layout/home']);
      })
    );
  }



}
